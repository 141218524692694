<template>
    <!-- <div id="pageHeaders" :style="`background:${headerColor.bg};color:${headerColor.color}`" :class="`clearMe_${lang.langname} pa-2 text-start`"> -->
    <div id="pageHeaders" :style="`background:#FFF;color:#000`" :class="`clearMe_${lang.langname} pa-2 text-start`">
        <div :class="`timeNow_${lang.langname} text-center`" style="display:none;" v-if="$store.getters['state'].group_id != 9">
            {{ timeNow }}</div>
        
        <img :class="`imglogo_${lang.langname}`" style="display:none;" :src="`${publicPath}${$store.state.logo}`" v-if="$store.state.licenseType.activemenu=='small'">
        <div class="gbackground m-2 mt-0" style="display:none;padding-top:10px !important;">
               &nbsp; {{ sitetitle }}
               <span class="logoutclass">
                   <i @click="$cookies.remove($COOKIEPhase),$cookies.remove($store.state.COOKIEPhase),cook = 'null', $router.push({name: 'Logout'})" class="fas fa-sign-out-alt" style="font-size:1.7em;cursor:pointer;"></i>
               </span>
        </div>
        <div class="flexIt">
            <div class="buttonKey" v-for="(item,index) in TopMenu" :key="index"
                :style="`background:`+item.bgcolor+` !important;color:`+item.color+` !important;`"
                @click="changeMenu(item.index,item.href,item.to,item.frame)"
            >{{item.text}}</div>
        </div>
       
        <v-card v-if="showTraining"
            class="pa-1"
            style="margin-inline-end:5px;padding:25px 1px !important;display:none"
            id="trainingMe"
            outlined
            :style="`background:black !important;font-weight:bold;width:120px;color:#FFF`"
            v-b-toggle.view_help
        >
            {{ lang.training }}
        </v-card>
            
            <div class="xloadingPage" v-if="showLoadIt">
            <v-progress-circular
                :width="3"
                color="green"
                indeterminate
            ></v-progress-circular>
                <div style="padding:3px;">يرجى الانتظار ... Please Wait</div>
            </div>
            <!-- <img :src="`img/refresh-icon.png`" @click="refreshPage();" class="refIcon" /> -->
            <div :class="`refIcon_${lang.langname}`" @click="refreshPage();">{{lang.refresh}}</div>
        <samHelpVue />
    </div>
</template>

<script>
import axios from 'axios'
import samHelpVue from '@/components/samHelp.vue'
// import renewalPage from '@/components/crm/renewalPage.vue'
import FloatButtonVue from './FloatButton.vue'
export default {
    components: {
        samHelpVue,
        // renewalPage
    },
    data() {
        return {
            // showit: true,
           showMessage: false,
           sitetitle: ' ',
           cook: '',
           publicPath: process.env.BASE_URL+'img/',
           showHeader: true,
           topMenuO: [],
           showExit: false,
           showTraining: false,
           timeNow: '',
           defMenu: 'erp',
           stopClick: false,
           count: 0,
        }
    },
    beforeCreated() {
        // this.getSettings();
        // this.drawMenu()
    },
    created() {
        // if(this.showHeader){
            this.getSettings();
            this.getUser();
            // this.drawMenu()
            this.showTraining = false;this.showTraining = true;
            // this.showIt();
            this.startIt();
            // setInterval(() => {this.getTimeNow()},1000)
            
        // }
    },

    methods: {
        refreshPage(){
            location.reload();
        },
        startIt()
        {
            let t = setInterval(() => {this.showIt(t);},1000)
        },
        showIt(t){
            this.count =parseFloat(this.count) + parseFloat(1);
            if(this.showMessage && !this.stopClick){
                // console.log(this.count);
                document.getElementById('showMessage').click()
                this.stopClick = true
            }
        },
        getTimeNow(){
            const date = new Date();
            let d = date.getDate();
            d = d < 10 ? "0"+d : d;
            let m = date.getMonth() + 1;
            m = m < 10 ? "0"+m : m;
            const y = date.getFullYear();
            let h = date.getHours();
            let dm = "AM";
            if(h == 0) h = 12;
            else if(h < 10) h = "0"+h;
            else if(h>12){h = parseInt(h) - parseInt(12);dm = "PM"}
            else if(h == 12) {dm = "PM"}

            let min = date.getMinutes();
            min = min < 10 ? "0"+min : min;
            let sec = date.getSeconds();
            sec = sec < 10 ? "0"+sec : sec;
            h = h < 10 ? "0"+h : h;
            this.timeNow = d+"-"+m+"-"+y+" "+h+":"+min+":"+sec+" "+dm;
        },
        gotoHome(){
            localStorage.setItem('currentMenu',1);
        },
        changeMenu(index ,href,to,frame){
            if(frame == 'iframe'){
                document.getElementById('trainingMe').click();
                // window.open("https://samcotec.com/training", "_blank");
                return false;
            }
            if(frame == 'logout'){
                this.$router.push({path: '/logout'})
                return false;
            }
            localStorage.setItem('currentMenu',index);
            if(href != this.$route.path){
                if(this.$store.state.licenseType.isCar){
                    this.$router.push({path: to})
                }else{
                    this.$router.push({path: href})
                }
            }
        },
        getSettings(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getSettings");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.error.number == 213 && this.$route.path != '/Login' && this.$route.path != '/login'){
                    this.$router.push({path: '/Login'})
                    return false;
                }
                if(res.results.d.showImg == 1){
                    this.showMessage = true;
                }else{
                    this.showMessage = false;
                }
                
                if(res.error.number != 200){
                    this.$router.push('/logout');
                    return false;
                }
                this.defMenu = localStorage.getItem("_ECar_");
                this.$store.state.settings = res.results.data;
                // this.drawMenu()
            })
        },
        getUser(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getUser");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    this.$store.state.group_id = res.results.data.group_id;
                }
            })
        },
        drawMenu(){
            // this.topMenuO = []
            this.topMenuO = this.TopMenu
        }
    },
    computed: {
        showLoadIt: function(){
            if(this.TopMenu.length != 0) return false;
            else return true;
        },
        headerColor: function(){
            let t = {
                bg: '#fff !important',
                color: '#000 !important'
            };
            const sEMenu = JSON.parse(localStorage.getItem("_ECar_"))
            if(sEMenu.activemenu != 'erpsam'){
                t.bg = '#e9e9e9 !important'
                t.color = 'rgb(0,0,0) !important'
            }else{
                t.bg = '#e9e9e9 !important'
                t.color = 'rgb(0,0,0) !important'
            }
            return t;
        },
        lang: {
            get: function(){
                if(this.$cookies.get(this.$store.state.LangCooki) != null){
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set: function(newValue){
                return newValue
            }
        },
        TopMenu:  function(){
            let t = [];
            const menu = this.$store.getters['state'].menu;
            // const license = this.$store.getters['state'].licenseType.activemenu;
            const ssMenu = localStorage.getItem('_ECar_');
            const license = (JSON.parse(ssMenu)).activemenu;
        
            // let currentMenu = menu[this.defMenu]; 
            let currentMenu = menu[license]; 
            if(typeof currentMenu === 'undefined')  return false;
            console.log("currentMenu",currentMenu)
            Object.values(currentMenu).forEach(value =>{
                
                if(this.lang.langname == 'ar'){
                    value.main.text = value.main.arname
                }else{
                    value.main.text = value.main.enname
                }
                // localStorage.setItem('subLang',this.lang.langname)
                // if(localStorage.getItem('subLang') == "ar"){
                //     value.main.text = value.main.arname
                // }
                // else if(localStorage.getItem('subLang') == "en"){
                //     value.main.text = value.main.enname
                // }
                // else if(localStorage.getItem('subLang') == "urdu"){
                //     value.main.text = value.main.urduname
                // }
                const cmenu = localStorage.getItem('currentMenu');
                if(typeof value.main.frame === 'undefined'){
                    value.main.frame = '';
                }
                
                if(typeof value.main.border === 'undefined'){
                    value.main.border = '#000';
                }
                if(value.main.frame == 'logout'){
                    //
                }
                if(cmenu == value.main.index){
                    value.main.bgcolor = '#000';
                    value.main.color = '#fff';
                }else if(cmenu == null){
                    if(value.main.index == 1){
                        value.main.bgcolor = '#000000';
                        value.main.color = '#ffffff';
                        localStorage.setItem('currentMenu',1);
                        this.changeMenu(1,value.main.href)
                    }
                }else{
                    value.main.bgcolor = '#ffffff';
                    value.main.color = '#000000';
                }
                // console.log(["button: " + value.main.text,value.main]);
                if(value.main.enname == 'Logout'){
                    value.main.bgcolor = '#FF0000';
                    value.main.color = '#ffffff';
                    value.main.border = '#FF0000';
                    value.main.frame = 'logout';
                }
                if(value.main.enname == 'training'){
                    value.main.frame = 'iframe';
                }
                // console.log(this.$store.getters['state'].group_id);
                
                for(let i =0; i< value.main.groupid.length;i++){
                    if(value.main.groupid[i] == this.$store.getters['state'].group_id){
                        t.push(value.main)
                    }
                }
            });
            // let training = {
            //     index: 100,
            //     arname: 'التدريب',
            //     enname: 'training',
            //     text: '',
            //     href: '',
            //     to: '',
            //     class: '',
            //     smclass: '', //smclass: 'font-small',
            //     groupid: [1,2,3,4,5,6,7,8],
            //     bgcolor: '#000000',
            //     color: '#ffffff',
            //     frame: 'iframe',
            //     border: '#000'
            // }
            // training.text = training[`${this.lang.langname}name`];
            // if(this.showTraining){
            //     t.push(training);
            // }
            // training = {
            //     index: 100,
            //     arname: 'تسجيل خروج',
            //     enname: 'Logout',
            //     text: '',
            //     href: '/logout',
            //     to: '/logout',
            //     class: '',
            //     smclass: '', //smclass: 'font-small',
            //     groupid: [1,2,3,4,5,6,7,8],
            //     bgcolor: '#FF0000',
            //     color: '#ffffff',
            //     frame: 'logout',
            //     border: '#FF0000'
            // }
            // training.text = training[`${this.lang.langname}name`];
            // if(this.showExit){
            //     t.push(training);
            // }
            // // console.log("topMenu",t);
            return t;
        },
        groupid: function() {
            return this.$store.state.group_id;
        },
        license: function(){
            return this.$store.getters['state'].licenseType.activemenu
        }
    },
    
}
</script>
<style>
.refIcon_ar{
    position:absolute;
    top:40px;
    left: 25px;
    right: unset;
    cursor:pointer;
    padding:5px 10px;
    background:darkgreen;
    color:#fff;
    border-radius: 5px;
}
.refIcon_en{
    position:absolute;
    top:40px;
    left: unset;
    right: 25px;
    cursor:pointer;
    padding:5px 10px;
    background:darkgreen;
    color:#fff;
    border-radius: 5px;
}
#pageHeaders{
    border:1px solid #bbb;
    margin-inline-start: 15px;
    margin-inline-end: 50px;
    box-shadow: 0px 0px 0 rgba(138, 143, 148, 0.8), inset -2px -2px 5px #ddd;
}
.flexIt{
    display: flex;
    justify-content: start;
    align-items: center;
}
.buttonKey{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 80px;
  padding: 4px 7px;
  font-size: .8rem;
  color: #ffffff;
  
  border-radius: 10px;
  box-shadow: 0px 5px 0 rgba(73, 80, 87, 0.8), inset -2px -2px 10px #aaa;
  cursor: pointer;
  margin-inline-end:8px;
}
.buttonKey:active{
    box-shadow: 0px 3px 0 rgba(73, 80, 87, 0.8), inset -7px -7px 10px #aaa;
    transform: translate(2px, 2px);
    transition: 0.2s;
}
.helpicon_ar{
    position:absolute;
    left: 10px;
    top:50px;
    width:80px;
    display:flex;
    justify-content: center;
    justify-items: center;
    background:#000;
    color:#fff;
    border-radius: 25px;
    padding:2px;
    z-index: 1000;
    cursor: pointer;
}
.helpicon_en{
    position:absolute;
    right: 10px;
    top:30px;
    width:80px;
    display:flex;
    justify-content: center;
    justify-items: center;
    background:#000;
    color:#fff;
    border-radius: 25px;
    padding:2px;
    z-index: 1000;
    cursor: pointer;
}
.gbackground{
    position: relative;
    z-index: 10;
    background: rgb(215,177,0);
    background: linear-gradient(
    -45deg, rgba(215,177,0,1) 0%, 
    #d7b100 88%, rgba(0,0,0,1) 88%, 
    rgba(0,0,0,1) 89%, rgba(215,177,0,1) 89%, 
    rgba(215,177,0,1) 91%, rgba(0,0,0,1) 91%, 
    rgba(0,0,0,1) 92%, rgba(215,177,0,1) 92%, 
    rgba(215,177,0,1) 94%, rgba(0,0,0,1) 94%, 
    rgba(0,0,0,1) 95%, rgba(215,177,0,1) 95%, 
    rgba(215,177,0,1) 100%
    );
    padding:0px !important;
}
.logoutclass{
    color:#FFF;
    position: absolute;
    left:5px;
    top: 10px;
}
.clearMe_ar{
    position: relative;
   /**background: rgb(215,177,0);**/
    direction:rtl;
    /**color:#FFF;**/
    padding:2px 10px 10px 5px;
}
.clearMe_en{
    position: relative;
    /**background: rgb(215,177,0);**/
    direction:ltr;
    /**color:#FFF;**/
    padding:2px 10px 10px 5px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
.imglogo{
    position: absolute;
    top:30px;
    left:50px;
    z-index: 1000;
    width:120px;
}
.imglogo_ar{
    position: absolute;
    top:30px;
    left:50px;
    z-index: 1000;
    width:120px;
}
.imglogo_en{
    position: absolute;
    top:30px;
    right:50px;
    z-index: 1000;
    width:120px;
}
td{
    border:2px solid #bbb !important;
}

th{ 
    border:2px solid #000 !important;
    border-bottom:2px solid #000 !important;
}
td{
    background:#efefef !important;
}
.timeNow_ar{
    direction: ltr;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background:#000;
    color:gold;
    font-size:1rem;
    position: absolute;
    top:30px;
    left: 30px;
    padding:10px;
    /* border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; */
    width:200px;
    height: 30px;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.timeNow_en{
    direction: ltr;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background:#000;
    color:gold;
    font-size:1rem;
    position: absolute;
    top:30px;
    right: 30px;
    padding:10px;
    /* border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; */
    width:200px;
    height: 30px;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.selborder option{
    border-bottom: 1px solid #fff !important;
    padding-bottom: 3px;
    padding-top: 3px;
}
.xloadingPage{
    position: fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background:#fff;
    z-index: 250000;
}
@media  only screen and (max-width: 1100px){
    .buttonKey{
        width:65px;
        height:40px;
        font-size:.55rem;
        margin-inline-end:5px;
    }
    .refIcon_ar{
        top:30px;
        left: 10px;
        font-size:.6rem;

    }
    .refIcon_en{
        top:30px;
        left: 10px;
        font-size:.6rem;

    }
}
@media  only screen and (max-width: 1280px){
    .buttonKey{
        width:90px;
        height:50px;
        font-size:.55rem;
        margin-inline-end:5px;
    }
    .refIcon_ar{
        top:30px;
        left: 10px;
        font-size:.6rem;

    }
    .refIcon_en{
        top:30px;
        left: 10px;
        font-size:.6rem;

    }
    .timeNow_ar{
        box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 2px;
    }
    .timeNow_en{
        box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 2px;
    }
}
</style>